// deno-ts-ignore-file
// deno-lint-ignore-file
/* eslint-disable no-var */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable quotes */
'use client';

import Preloader from '@/components/preloader';
import PageLoader from '@/libraries/pageloader';
import { useContext, useState, useEffect } from 'react';
import * as NProgress from 'nprogress';

/**
 *
 * Page Loader
 *      - Built off of: npm NextTopLoader
 *
 */
export default function Loader(){


    /**
    * Convert the url to Absolute URL based on the current window location.
    * @param url {string}
    * @returns {string}
    */
    const toAbsoluteURL = (url) => new URL(url, window.location.href).href;

    /**
    * Check if it is hash anchor or same page anchor
    * @param currentUrl {string} Current Url Location
    * @param newUrl {string} New Url detected with each anchor
    * @returns {boolean}
    */
    const isHashAnchor = (currentUrl, newUrl) => {
        const current   = new URL(toAbsoluteURL(currentUrl));
        const next      = new URL(toAbsoluteURL(newUrl));
        return current.href.split('#')[0] === next.href.split('#')[0];
    };

    /**
    * Check if it is Same Host name
    * @param currentUrl {string} Current Url Location
    * @param newUrl {string} New Url detected with each anchor
    * @returns {boolean}
    */
    const isSameHostName = (currentUrl, newUrl) => {
        const current   = new URL(toAbsoluteURL(currentUrl));
        const next      = new URL(toAbsoluteURL(newUrl));
        return current.hostname.replace(/^www\./, '') === next.hostname.replace(/^www\./, '');
    };

    useEffect(() => {

        /**
         * Check if the Current Url is same as New Url
         * @param currentUrl {string}
         * @param newUrl {string}
         * @returns {boolean}
         */
        function isAnchorOfCurrentUrl(currentUrl, newUrl){
            const currentUrlObj   = new URL(currentUrl);
            const newUrlObj       = new URL(newUrl);

            // Compare hostname, pathname, and search parameters
            if (
                currentUrlObj.hostname === newUrlObj.hostname &&
                currentUrlObj.pathname === newUrlObj.pathname &&
                currentUrlObj.search === newUrlObj.search
            ) {
                // Check if the new URL is just an anchor of the current URL page
                const currentHash   = currentUrlObj.hash;
                const newHash       = newUrlObj.hash;
                return (
                    currentHash !== newHash && currentUrlObj.href.replace(currentHash, '') === newUrlObj.href.replace(newHash, '')
                );
            }
            return false;
        }

        // deno-lint-ignore no-var
        var nProgressClass = document.querySelectorAll('html');

        //
        const removeNProgressClass = () => nProgressClass.forEach( (el) => el.classList.remove('nprogress-busy') );

        /**
        * Find the closest anchor to trigger
        * @param element {HTMLElement | null}
        * @returns element {Element}
        */
        function findClosestAnchor(element){
            while (element && element.tagName.toLowerCase() !== 'a') {
                element = element.parentElement;
            }
            return element;
        }

        /**
         *
         * @param event {MouseEvent}
         * @returns {void}
         */
        function handleClick(event){
            try {
                const target = event.target;
                const anchor = findClosestAnchor(target);
                const newUrl = anchor?.href;
                if (newUrl) {
                    const currentUrl = window.location.href;

                    // const newUrl = (anchor as HTMLAnchorElement).href;
                    const isExternalLink = (anchor).target === '_blank';

                    // Check for Special Schemes
                    const isSpecialScheme = ['tel:', 'mailto:', 'sms:', 'blob:', 'download:'].some((scheme) => newUrl.startsWith(scheme) );

                    const isAnchor = isAnchorOfCurrentUrl(currentUrl, newUrl);
                    const notSameHost = !isSameHostName(window.location.href, anchor.href);

                    //If its' the same page, do nothing
                    if (notSameHost) return;

                    //
                    if (
                        newUrl === currentUrl ||
                        isAnchor ||
                        isExternalLink ||
                        isSpecialScheme ||
                        event.ctrlKey ||
                        event.metaKey ||
                        event.shiftKey ||
                        event.altKey ||
                        isHashAnchor(window.location.href, anchor.href) ||
                        !toAbsoluteURL(anchor.href).startsWith('http')
                    ) {
                        NProgress.start();
                        NProgress.done();
                        removeNProgressClass();
                    } else {
                        PageLoader.start();
                    }
                }
            } catch (err) {
                // Log the error in development only!
                // console.log('NextTopLoader error: ', err);
                NProgress.start();
                NProgress.done();
            }
        }

        /**
         * Complete TopLoader Progress
         * @param {History}
         * @returns {void}
         */
        ((history) => {
            const pushState = history.pushState;
            history.pushState = (...args) => {
                PageLoader.stop();
                return pushState.apply(history, args);
            };
        })((window).history);

        function handlePageHide(){
            PageLoader.stop();
        }

        /**
         * Handle Browser Back and Forth Navigation
         * @returns {void}
         */
        function handleBackAndForth(){
            PageLoader.stop();
        }

        // Add the global click event listener
        window.addEventListener('popstate', handleBackAndForth);
        document.addEventListener('click', handleClick);
        window.addEventListener('pagehide', handlePageHide);

        // Clean up the global click event listener when the component is unmounted
        return () => {
            document.removeEventListener('click', handleClick);
            window.removeEventListener('pagehide', handlePageHide);
            window.removeEventListener('popstate', handleBackAndForth);
        };
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Preloader status={ true } id="pageloader" />
};
