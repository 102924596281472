/**
*
*   Page Loader Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import * as NProgress from 'nprogress';

//The Page Loader Class
const PageLoader = (()=>{

    //Configure NProgress
    NProgress.configure({
        showSpinner:    false,
        trickle:        true,
        trickleSpeed:   200,
        minimum:        0.08,
        easing:         'ease',
        speed:          200
    });

    return {

        /**
        *
        *
        *
        *
        **/
        start: () => {

            //Start NProgress
            NProgress.start();

            //Add State Attribute
            document.body.setAttribute('data-page-state', 'loading');

        },

        /**
        *
        *
        *
        *
        **/
        stop: () => {

            //Finish NProgress
            NProgress.done();

            //Remove any progress states
            document.querySelectorAll('html').forEach( (el) => el.classList.remove('nprogress-busy') );

            //Remove State Attribute
            document.body.removeAttribute('data-page-state', 'loading');

            //Close any menu's
            document.body.classList.remove('mopen');

        }

    }

})();


export default PageLoader;