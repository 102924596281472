/**
*
*   Website Head Content
*       - All the Header SCripts
*
**/
'use client';

import Script from 'next/script'
import { Head } from 'next/head';
import { Fragment } from 'react';
import { usePathname } from 'next/navigation';
import { GoogleAnalytics } from '@next/third-parties/google';

//Build the Element
export default function Scripts({ ...props }){

    const pathname = usePathname();

    return (
        <head>

            {/* Hyros
            <Script src="https://t.fernandpetal.ca/v1/lst/universal-script?ph=9ea2151e483b18e54a77acdfc13dab915d3980b202e837b23fb5122b9d3aba64&tag=!tracking" />
            */}

            {/* Google Analytics */}
            <GoogleAnalytics gaId="G-8Q4H5GQ5NX" />
            {/*
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-MKSNXHYC48" onLoad={()=>{
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){
                        dataLayer.push(arguments);
                    }
                    gtag('js', new Date());
                    gtag('config', 'G-MKSNXHYC48');
            }} />
             */}

            {/* Default */}
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta name="p:domain_verify" content="5e052fbabcab47b0fe1b5a48d1f29dad"/>
            <meta name="facebook-domain-verification" content="523gcs1ssth1l4ew0egvtpqfmrzhno" />

            {/* Country Codes */}
            <link rel="alternate" hrefLang="x-default" href={ 'https://fernandpetal.com' + pathname.replace(/^\/fr\//,'/') } />
            <link rel="alternate" hrefLang="en-US" href={ 'https://fernandpetal.com' + pathname.replace(/^\/fr\//,'/') } />
            <link rel="alternate" hrefLang="en-CA" href={ 'https://fernandpetal.ca' + pathname.replace(/^\/fr\//,'/') } />
            <link rel="alternate" hrefLang="fr-CA" href={ 'https://fernandpetal.ca/fr' + pathname.replace(/^\/fr\//,'/') } />

            {/* Canonical */}
            <link rel="shortcut icon" href="/assets/logos/favicon.svg" />

            {/* Styles */}
            <link rel="stylesheet" media="all" href={ 'https://use.typekit.net/ncg0zfr.css' } crossOrigin="anonymous" />

            {/* Scripts */}
            <Script strategy="lazyOnload" src="https://kit.fontawesome.com/e23f4917a1.js" crossOrigin="anonymous" />
            <Script strategy="lazyOnload" src="https://connect.facebook.net/en_US/sdk.js" />

            {/* Tik Tok */}
            <Script strategy="lazyOnload" onLoad={()=>{
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                  ttq.load('CA5SFORL0K1AFEOJ07C0');
                  ttq.page();
                }(window, document, 'ttq');
            }}/>

            {/* Facebook */}
            <Script id="facebook-pixel" strategy="afterInteractive">{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            `}</Script>

            {/* Klaviyo */}
            <Script src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=RuFegQ" strategy="afterInteractive" />

            {/* Affirm */}
            <Script strategy="afterInteractive" onLoad={()=>{
                var _affirm_config = {
                    public_api_key:     '64OGUTPXRPIHGT2G',
                    script:             'https://cdn1.affirm.com/js/v2/affirm.js',
                    locale:             'en_CA',
                    country_code:       'CAN',
                };
                (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
                // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.
            }} />

            {/* Intercom */}
            <Script strategy="lazyOnload" onLoad={()=>{
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/a4r2mo60';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            }} />

        </head>
    )

}